<template>
  <div>
    <div class="page-title">
      <h3>Reports</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li class="active">
            Reports
          </li>
        </ol>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div
          :class="(jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor') ? 'col-lg-offset-1 col-lg-10 col-md-12'
            : 'col-lg-offset-3 col-lg-6 col-md-offset-2 col-md-8 col-sm-offset-2 col-sm-8 col-xs-offset-1 col-xs-10'"
        >
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Report Configuration
              </h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div
                  :class="(jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor' || jobType === 'BackOffice') ? 'col-md-4' : 'col-md-6 col-xs-12'"
                  class="m-b-sm"
                >
                  <div class="row">
                    <span class="col-xs-12">Date Range: </span>
                    <date-range-picker
                      ref="picker"
                      v-model="dataDateRange"
                      :auto-apply="true"
                      :locale-data="getLocaleData"
                      :max-date="getDateToday"
                      :opens="'right'"
                      :ranges="dateRangePresets"
                      class="col-xs-12"
                      @update="dataDateRangeUpdate"
                    >
                      <div
                        slot="input"
                        slot-scope="picker"
                        style="min-width: 250px;"
                      >
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </div>
                    </date-range-picker>
                  </div>
                </div>
                <div
                  :class="(jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor' || jobType === 'BackOffice') ? 'col-md-4 col-xs-6' : 'col-md-6 col-xs-12'"
                >
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="selectedStatuses"
                      :allow-empty="true"
                      :close-on-select="false"
                      :limit="1"
                      :multiple="true"
                      :options="_getStatuses"
                      :placeholder="null"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    />
                    <span class="float-label">Statuses: </span>
                  </label>
                </div>
                <div
                  v-if="jobType === 'Owner' || jobType === 'BackOffice'"
                  class="col-md-4 col-xs-6"
                >
                  <label class="has-float-label">
                    <VueMultiselect
                      v-model="activeBranchId"
                      :allow-empty="false"
                      :multiple="false"
                      :options="_getBranches"
                      :placeholder="null"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    />
                    <span class="float-label">Branch: </span>
                  </label>
                </div>
                <div
                  v-if="jobType === 'Manager' || jobType === 'Supervisor'"
                  class="form-group col-md-4 col-xs-6"
                >
                  <label class="has-float-label">
                    <label class="has-float-label">
                      <VueMultiselect
                        v-model="selectedUsers"
                        :allow-empty="true"
                        :class="{ 'placeholder-shown': (!selectedUsers || selectedUsers === '') }"
                        :custom-label="getUserName"
                        :multiple="true"
                        :options="_getUsers"
                        :placeholder="null"
                        :show-labels="false"
                        track-by="username"
                      />
                      <span class="float-label">User: </span>
                    </label>
                  </label>
                </div>
              </div>
              <div class="row">
                <div
                  v-if="jobType === 'Owner'"
                  class="form-group col-md-4 col-xs-12"
                >
                  <label class="has-float-label">
                    <label class="has-float-label">
                      <VueMultiselect
                        v-model="selectedUsers"
                        :allow-empty="true"
                        :class="{ 'placeholder-shown': (!selectedUsers || selectedUsers === '') }"
                        :custom-label="getUserName"
                        :multiple="true"
                        :options="_getUsers"
                        :placeholder="null"
                        :show-labels="false"
                        track-by="username"
                      />
                      <span class="float-label">User: </span>
                    </label>
                  </label>
                </div>
                <div
                  v-if="jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor' || jobType === 'BackOffice'"
                  class="col-md-2 col-xs-3"
                >
                  <div class="row">
                    <span class="col-xs-12 m-b-xs">All fields: </span>
                    <toggle-button
                      v-model="allFields"
                      :sync="true"
                      class="col-xs-12"
                      color="#22baa0"
                    />
                  </div>
                </div>
                <div
                  :class="(jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor') ? 'col-md-2 col-xs-3' : 'col-xs-4'"
                >
                  <div class="row">
                    <span class="col-xs-12 m-b-xs">Form history: </span>
                    <toggle-button
                      v-model="formHistory"
                      :sync="true"
                      class="col-xs-12"
                      color="#22baa0"
                    />
                  </div>
                </div>
                <div
                  :class="(jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor') ? 'col-md-2 col-xs-3' : 'col-xs-4'"
                >
                  <div class="row">
                    <span class="col-xs-12 m-b-xs">Charts: </span>
                    <toggle-button
                      v-model="chartEnabled"
                      :sync="true"
                      class="col-xs-12"
                      color="#22baa0"
                    />
                  </div>
                </div>
                <div
                  v-if="jobType !== 'User'"
                  class="col-md-2 col-xs-3"
                >
                  <div class="row">
                    <span class="col-xs-12 m-b-xs">Team: </span>
                    <toggle-button
                      v-model="teamEnabled"
                      :sync="true"
                      class="col-xs-12"
                      color="#22baa0"
                    />
                  </div>
                </div>
              </div>
              <div class="row m-t-lg">
                <div
                  :class="(jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor') ? 'col-md-2' : 'col-md-4'"
                >
                  <button
                    id="applyReportConfig"
                    class="btn btn-block btn-info"
                    type="button"
                    @click.stop.prevent="fetchForms"
                  >
                    Apply
                  </button>
                </div>
                <div
                  :class="(jobType === 'Owner' || jobType === 'Manager' || jobType === 'Supervisor') ? 'col-md-4' : 'col-md-8'"
                  class="m-t-xs"
                  style="font-size: 14px; font-weight: 600"
                >
                  <span
                    v-if="forms.length < 1 && !loadingData && formsFetched"
                    class="text-danger"
                  >
                    No data available in this report
                  </span>
                  <span
                    v-if="loadingData"
                    class="text-info"
                  >
                    Loading data...
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="chartEnabled"
        class="row"
      >
        <div
          v-show="enableStatusChart"
          class="col-md-6"
        >
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Status Breakdown
              </h3>
            </div>
            <div class="panel-body">
              <doughnut-chart
                :chart-data="statusChartData"
                :options="statusChartOptions"
              />
            </div>
          </div>
        </div>
        <div
          v-show="enableDateChart"
          class="col-md-6"
        >
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Time Breakdown
              </h3>
            </div>
            <div class="panel-body">
              <bar-chart
                :chart-data="dateChartData"
                :options="dateChartOptions"
              />
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div
        v-if="jobType !== 'User'"
        v-show="teamEnabled"
        class="row"
      >
        <div
          v-show="chartEnabled"
          class="col-md-6"
        >
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Team Comparison
              </h3>
            </div>
            <div
              v-show="!loadingData && forms.length > 0"
              class="panel-body"
            >
              <doughnut-chart
                :chart-data="teamChartData"
                :options="teamChartOptions"
              />
            </div>
          </div>
        </div>
        <div :class="chartEnabled ? 'col-md-6' : 'col-md-12'">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Team Breakdown
              </h3>
            </div>
            <div
              v-show="!loadingData && forms.length > 0"
              class="panel-body"
            >
              <TeamBreakdownTable
                :enabled="teamEnabled"
                :forms="forms"
              />
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h2 class="panel-title text-center">
                Forms
              </h2>
            </div>
            <div
              v-show="!loadingData && forms.length > 0"
              class="panel-body"
            >
              <div class="table-responsive">
                <table
                  id="submittedFormsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th class="createdAt includeInExport">
                        Submission Date
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="createdAtTime"
                      >
                        Submission Time
                      </th>
                      <th class="bankVerificationDate includeInExport">
                        Bank Verification Date
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="previouslyAppliedDate"
                      >
                        Previously Applied Date
                      </th>
                      <th class="bankReceivedDate includeInExport">
                        Bank Received Date
                      </th>
                      <th class="bankResultDate includeInExport">
                        Bank Result Date
                      </th>
                      <th class="city includeInExport">
                        Resident City
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="dateNinetyDays"
                      >
                        Ninety Days Date
                      </th>
                      <th class="owner includeInExport">
                        Owner
                      </th>
                      <th class="supervisor includeInExport">
                        Supervisor
                      </th>
                      <th class="branchManager includeInExport">
                        Branch Manager
                      </th>
                      <th class="status includeInExport">
                        Status
                      </th>
                      <th class="statusJustifications includeInExport">
                        Status Justifications
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="appId"
                      >
                        Application #
                      </th>
                      <th class="referenceNumber includeInExport">
                        Reference Number
                      </th>
                      <th class="remarks includeInExport">
                        Remarks
                      </th>
                      <th class="offerType includeInExport">
                        Offer Type
                      </th>
                      <th class="product includeInExport">
                        Product
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="nameDesired"
                      >
                        Name Desired On Card
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="title"
                      >
                        Title
                      </th>
                      <th class="firstName includeInExport">
                        First Name
                      </th>
                      <th class="lastName includeInExport">
                        Last Name
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="fatherName"
                      >
                        Father Name
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="motherName"
                      >
                        Mother Name
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="dateOfBirth"
                      >
                        Date Of Birth
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="age"
                      >
                        Age
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="gender"
                      >
                        Gender
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="maritalStatus"
                      >
                        Marital Status
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="education"
                      >
                        Education
                      </th>
                      <th class="pancard includeInExport">
                        Pancard
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="cibilScore"
                      >
                        CIBIL Score
                      </th>
                      <th class="email includeInExport">
                        Email
                      </th>
                      <th class="emailOfficial includeInExport">
                        Email Official
                      </th>
                      <th class="mobileNumber">
                        <!--                      <th class="mobileNumber includeInExport">-->
                        Mobile Number
                      </th>
                      <th class="mobileNumberAlternate">
                        <!--                      <th class="mobileNumberAlternate includeInExport">-->
                        Alternate Number
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="residenceType"
                      >
                        Residence Type
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="stayingSince"
                      >
                        Staying Since
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="addressLine1"
                      >
                        Resident Address Line 1
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="addressLine2"
                      >
                        Resident Address Line 2
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="landmark"
                      >
                        Resident Landmark
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="station"
                      >
                        Resident Station
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="zipcode"
                      >
                        Resident Pincode
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="employmentStatus"
                      >
                        Employment Status
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="netMonthlyIncome"
                      >
                        Net Monthly Income
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="yearsEmployed"
                      >
                        Years Employed
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="designation"
                      >
                        Designation
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="company"
                      >
                        Company
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="companyListed"
                      >
                        Company Listed?
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="companyAddressLine1"
                      >
                        Company Address Line 1
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="companyAddressLine2"
                      >
                        Company Address Line 2
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="companyLandmark"
                      >
                        Company Landmark
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="companyStation"
                      >
                        Company Station
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="companyCity"
                      >
                        Company City
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="companyZipcode"
                      >
                        Company Pincode
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCBank1"
                      >
                        Existing CC Bank 1
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCUsingFrom1"
                      >
                        Existing CC Using From 1
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCTotalLimit1"
                      >
                        Existing CC Total Limit 1
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCAvailableLimit1"
                      >
                        Existing CC Available Limit 1
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCBank2"
                      >
                        Existing CC Bank 2
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCUsingFrom2"
                      >
                        Existing CC Using From 2
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCTotalLimit2"
                      >
                        Existing CC Total Limit 2
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCAvailableLimit2"
                      >
                        Existing CC Available Limit 2
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCBank3"
                      >
                        Existing CC Bank 3
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCUsingFrom3"
                      >
                        Existing CC Using From 3
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCTotalLimit3"
                      >
                        Existing CC Total Limit 3
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCAvailableLimit3"
                      >
                        Existing CC Available Limit 3
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCBank4"
                      >
                        Existing CC Bank 4
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCUsingFrom4"
                      >
                        Existing CC Using From 4
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCTotalLimit4"
                      >
                        Existing CC Total Limit 4
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCAvailableLimit4"
                      >
                        Existing CC Available Limit 4
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="cardMailingAddress"
                      >
                        Send Card To
                      </th>
                      <th
                        :class="{'includeInExport' : allFields}"
                        class="extCCNumber"
                      >
                        Existing CC Number
                      </th>
                      <th class="updatedBy includeInExport">
                        Last Updated By
                      </th>
                      <th class="updatedAt includeInExport">
                        Updated On
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <AIPFormModal
        :form-details="formDetails"
        :job-type="jobType"
        :show-all-fields="allFields"
        :show-history="formHistory"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import VueMultiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import DoughnutChart from '../../components/DoughnutChart';
import BarChart from "../../components/BarChart";
import {chartColours} from "@/constants";
import AIPFormModal from "../../components/AIPFormModal";
import TeamBreakdownTable from "../../components/TeamBreakdownTable";

export default {
  name: "Reports",
  components: {
    DateRangePicker,
    DoughnutChart,
    BarChart,
    VueMultiselect,
    AIPFormModal,
    TeamBreakdownTable
  },
  filters: {
    date: function (date) {
      return moment(date).format('MMM Do, YYYY');
    }
  },
  data() {
    return {
      formDetails: {},
      forms: [],
      formsTable: null,
      teamChartData: {
        labels: [],
        datasets: []
      },
      teamChartOptions: {},
      statusChartData: {
        labels: [],
        datasets: []
      },
      statusChartOptions: {},
      enableStatusChart: false,
      dateChartData: {
        labels: [],
        datasets: []
      },
      dateChartOptions: {},
      enableDateChart: false,
      dataDateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      dateRangePresets: {
        'Today': [moment().startOf('day').toDate(), moment().startOf('day').toDate()],
        'Yesterday': [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').startOf('day').toDate()],
        'This week': [moment().startOf('week').toDate(), moment().startOf('day').toDate()],
        'This month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
        'Last week': [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()],
        'Last month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
      },
      activeBranchId: null,
      jobType: '',
      allFields: false,
      formHistory: false,
      chartEnabled: false,
      teamEnabled: true,
      selectedStatuses: [],
      selectedUsers: [],
      loadingData: false,
      formsFetched: false
    };
  },
  beforeRouteLeave(to, from, next) {
    let formModal = $("#formDetailsModal");
    if ((formModal.data('bs.modal') || {}).isShown) {
      formModal.modal('hide');
      document.getElementById('submittedFormsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getBranches",
      "getStatuses",
      "getProducts",
      "getUsers",
      "getTitles"
    ]),
    ...mapState([
      'loggedInUser',
      'deleteSubscriptionForm'
    ]),
    _getStatuses() {
      return _.sortBy(_.filter(this.getStatuses, (status) => {
        return _.includes(status.selectable, this.jobType) || this.jobType === 'Owner' || this.jobType === 'User' || this.jobType === 'Supervisor' || this.jobType === 'Manager' || this.jobType === 'Partner';
      }), 'name');
    },
    _getBranches() {
      //TODO: Filter to only include the branches that the user has access to
      return _.sortBy(this.getBranches, 'name');
    },
    _getUsers() {
      return _.sortBy(_.filter(this.getUsers, (user) => {
        const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
          return title.id === user.jobTitle
        }), 'access');
        return _.includes(['User', 'Supervisor', 'Manager'], currentUserJobTitle) && user.id !== this.getLoggedInUser.id;
      }), (user) => {
        return this.getUserName(user);
      });
    },
    getStats() {
      return (groupBy, groupType) => {
        let count = 0;
        let group = _.groupBy(this.forms, groupBy)[groupType];
        if (group && group.length > 0) {
          count = group.length;
        }
        return count;
      }
    },
    getLocaleData() {
      return {
        format: 'MMM DD YYYY',
        firstDay: moment.localeData().firstDayOfWeek()
      }
    },
    getDateToday() {
      return moment().format('LL');
    }
  },
  watch: {
    chartEnabled(newValue) {
      if (newValue) {
        this.updateStatusChartData();
        this.updateDateChartData();
        if (this.teamEnabled) {
          this.updateTeamChartData();
        }
      }
    },
    teamEnabled(newValue) {
      if (newValue) {
        if (this.chartEnabled) {
          this.updateTeamChartData();
        }
      }
    },
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        if (newValue.jobTitle && newValue.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === newValue.jobTitle;
          });
          if (jobTitle) {
            this.jobType = jobTitle.access;
          }
          this.activeBranchId = _.filter(this.getBranches, (branch) => {
            return branch.number === newValue.branchId[0];
          })[0];
          if (this.formsTable && jobTitle === 'Owner') {
            this.formsTable.button().add(2, {
              extend: 'excelHtml5',
              autoFilter: true,
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel'
            });
          }
          this.fetchForms();
        }
      }
    },
    deleteSubscriptionForm(newValue) {
      let oldForms = _.cloneDeep(this.forms);
      _.remove(oldForms, (oldForm) => {
        return oldForm.id === newValue.id;
      });
      this.forms = oldForms;
      this.formsTable.row("#" + newValue.id)
          .remove()
          .draw();
    }
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
        }
        this.activeBranchId = _.filter(this.getBranches, (branch) => {
          return branch.number === this.getLoggedInUser.branchId[0];
        })[0];
        if (this.jobType === 'User') {
          this.teamEnabled = false;
        }
      }

      this.createFormsTable();
      this.updateTeamChartData();
      this.updateStatusChartData();
      this.updateDateChartData();
    }
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    getUserName: function (user) {
      if (user) {
        let name = _.capitalize(user.firstName);

        if (user.lastName) {
          name += " " + _.capitalize(user.lastName);
        }
        return name;
      } else {
        return '';
      }
    },
    async fetchForms() {
      if (!_.includes(['Owner', 'Partner', 'Manager', 'Supervisor', 'User'], this.jobType) && (!this.selectedStatuses || this.selectedStatuses.length < 1)) {
        this.setMessage('v-notify-error', "Error!", 'You must select at least one status from the dropdown!');
        return;
      }
      this.loadingData = true;
      this.formsFetched = true;
      this.forms = [];
      let filter = null;

      if (this.jobType === 'Owner' || this.jobType === 'Partner' || this.jobType === 'BackOffice') {
        filter = {
          branchId: {
            eq: (this.activeBranchId && this.activeBranchId.number ? this.activeBranchId.number : 1)
          }
        };
      } else if (this.jobType === 'Supervisor') {
        filter = {
          supervisor: {
            eq: this.getLoggedInUser.username
          }
        };
      } else if (this.jobType === 'Manager') {
        filter = {
          branchManager: {
            eq: this.getLoggedInUser.username
          }
        };
      }
      if (this.getLoggedInUser.products && this.getLoggedInUser.products.length > 0) {
        if (filter) {
          filter.product = {
            eq: this.getLoggedInUser.products[0]
          };
        } else {
          filter = {
            product: {
              eq: this.getLoggedInUser.products[0]
            }
          };
        }
      }
      let statuses = null;
      if (!this.selectedStatuses || this.selectedStatuses.length < 1) {
        if (this.jobType === 'User') {
          this.fetchFormsByEmployeeSubmittedDate(this.getLoggedInUser.username, moment(this.dataDateRange.startDate).startOf('day').format('X'), moment(this.dataDateRange.endDate).endOf('day').format('X'), filter, null).then(() => {
            this.loadingData = false;
            this.updateStatusChartData();
            this.updateDateChartData();
            this.updateFormsTableData();
          });
        } else {
          statuses = _.map(this.getStatuses, 'id');
          let promises = [];
          if (!this.selectedUsers || this.selectedUsers.length < 1) {
            _.forEach(statuses, (status) => {
              promises.push(this.fetchFormsByStatusSubmittedDate(status, moment(this.dataDateRange.startDate).startOf('day').format('X'), moment(this.dataDateRange.endDate).endOf('day').format('X'), filter, null));
            });
          } else {
            _.forEach(this.selectedUsers, (user) => {
              const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
                return title.id === user.jobTitle
              }), 'access');
              let finalFilter = _.cloneDeep(filter);
              if (currentUserJobTitle === 'User') {
                finalFilter.owner = {
                  eq: user.username
                };
              } else if (currentUserJobTitle === 'Supervisor') {
                finalFilter.supervisor = {
                  eq: user.username
                };
              } else if (currentUserJobTitle === 'Manager') {
                finalFilter.branchManager = {
                  eq: user.username
                };
              }
              _.forEach(statuses, (status) => {
                promises.push(this.fetchFormsByStatusSubmittedDate(status, moment(this.dataDateRange.startDate).startOf('day').format('X'), moment(this.dataDateRange.endDate).endOf('day').format('X'), finalFilter, null));
              });
            })
          }
          Promise.all(promises).then(() => {
            this.loadingData = false;
            this.updateTeamChartData();
            this.updateStatusChartData();
            this.updateDateChartData();
            this.updateFormsTableData();
          });
        }
      } else {
        statuses = _.map(this.selectedStatuses, 'id');
        let promises = [];
        if (!this.selectedUsers || this.selectedUsers.length < 1) {
          _.forEach(statuses, (status) => {
            promises.push(this.fetchFormsByStatusAction(status, moment(this.dataDateRange.startDate).startOf('day').format('X'), moment(this.dataDateRange.endDate).endOf('day').format('X'), null, null, filter, null));
          });
        } else {
          _.forEach(this.selectedUsers, (user) => {
            const currentUserJobTitle = _.result(_.find(this.getTitles, (title) => {
              return title.id === user.jobTitle
            }), 'access');
            let finalFilter = _.cloneDeep(filter);
            let selectedUser = null;
            if (currentUserJobTitle === 'User') {
              selectedUser = user.username;
            } else if (currentUserJobTitle === 'Supervisor') {
              finalFilter.supervisor = {
                eq: user.username
              };
            } else if (currentUserJobTitle === 'Manager') {
              finalFilter.branchManager = {
                eq: user.username
              };
            }
            _.forEach(statuses, (status) => {
              promises.push(this.fetchFormsByStatusAction(status, moment(this.dataDateRange.startDate).startOf('day').format('X'), moment(this.dataDateRange.endDate).endOf('day').format('X'), currentUserJobTitle, selectedUser, finalFilter, null));
            });
          })
        }
        Promise.all(promises).then(() => {
          this.loadingData = false;
          this.updateTeamChartData();
          this.updateStatusChartData();
          this.updateDateChartData();
          this.updateFormsTableData();
        })
      }
    },
    async fetchFormsByEmployeeSubmittedDate(username, dateStart, dateEnd, filter = null, nextPage = null) {
      const {
        data: {
          formsByEmployeeSubmittedDate: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(
          this.formHistory ?
              (this.allFields ? customQueries.formsByEmployeeSubmittedDateWithHistoryAllFields : customQueries.formsByEmployeeSubmittedDateWithHistory) :
              (this.allFields ? queries.formsByEmployeeSubmittedDate : customQueries.countFormsByEmployeeSubmittedDate)
          , {
            owner: username,
            createdAtEpoch: {
              between: [dateStart, dateEnd]
            },
            limit: 99,
            filter: filter,
            nextToken: nextPage
          }));
      this.forms = _.concat(this.forms, items);
      nextPage = nextToken;
      if (nextPage) {
        await this.fetchFormsByEmployeeSubmittedDate(username, dateStart, dateEnd, filter, nextPage);
      }
    },
    async fetchFormsByStatusSubmittedDate(status, dateStart, dateEnd, filter = null, nextPage = null) {
      const {
        data: {
          formsByStatusSubmittedDate: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(
          this.formHistory ?
              (this.allFields ? customQueries.formsByStatusSubmittedDateWithHistoryAllFields : customQueries.formsByStatusSubmittedDateWithHistory) :
              (this.allFields ? queries.formsByStatusSubmittedDate : customQueries.countFormsByStatusSubmittedDate)
          , {
            status: status,
            createdAtEpoch: {
              between: [dateStart, dateEnd]
            },
            limit: 99,
            filter: filter,
            nextToken: nextPage
          }));
      this.forms = _.uniqBy(_.concat(this.forms, items), 'id');
      nextPage = nextToken;
      if (nextPage) {
        await this.fetchFormsByStatusSubmittedDate(status, dateStart, dateEnd, filter, nextPage);
      }
    },
    async fetchFormsByStatusAction(status, dateStart, dateEnd, selectedUserJobType = null, selectedUser = null, filter = null, nextPage = null) {
      let logFilter = null;
      if (this.jobType === 'User') {
        logFilter = {
          formOwner: {
            eq: this.getLoggedInUser.username
          }
        };
      } else if (selectedUserJobType && selectedUserJobType !== '') {
        if (selectedUser !== null && selectedUser !== '') {
          if (selectedUserJobType === 'User') {
            logFilter = {
              formOwner: {
                eq: selectedUser
              }
            };
          } else if (selectedUserJobType !== 'Manager' && selectedUserJobType !== 'Supervisor') {
            logFilter = {
              owner: {
                eq: selectedUser
              }
            };
          }
        }
      }
      const {
        data: {
          formsByActionWithEpoch: {items, nextToken}
        }
      } = await API.graphql(graphqlOperation(
          this.formHistory ?
              (this.allFields ? customQueries.formsByActionWithEpochWithHistoryAllFields : customQueries.formsByActionWithEpochWithHistory) :
              (this.allFields ? customQueries.formsByActionWithEpochAllFields : customQueries.countFormsByActionWithEpoch)
          , {
            action: status,
            actionEpoch: {
              between: [dateStart, dateEnd]
            },
            filter: logFilter,
            limit: 999,
            nextToken: nextPage
          }));
      let forms = _.map(items, 'form');
      if (filter) {
        if (filter.branchId) {
          forms = _.filter(forms, {'branchId': filter.branchId.eq})
        } else if (filter.supervisor) {
          forms = _.filter(forms, {'supervisor': filter.supervisor.eq})
        } else if (filter.branchManager) {
          forms = _.filter(forms, {'branchManager': filter.branchManager.eq})
        }
        if (filter.product) {
          forms = _.filter(forms, {'product': filter.product.eq})
        }
      }
      _.pull(forms, null);
      this.forms = _.uniqBy(_.concat(this.forms, forms), 'id');
      nextPage = nextToken;
      if (nextPage) {
        await this.fetchFormsByStatusAction(status, dateStart, dateEnd, selectedUserJobType, selectedUser, filter, nextPage);
      }
    },
    dataDateRangeUpdate: function (newDateRange) {
      this.dataDateRange.startDate = newDateRange.startDate;
      this.dataDateRange.endDate = newDateRange.endDate;
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      } else if (type === 'Currency' && value && value !== '') {
        value = value.toString().replace(/,/g, '');
        let lastThree = value.substring(value.length - 3);
        let otherNumbers = value.substring(0, value.length - 3);
        if (otherNumbers !== '') {
          lastThree = ',' + lastThree;
        }
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      } else if (type === 'Status') {
        return _.result(_.find(this.getStatuses, (status) => {
          return status.id === value;
        }), 'name') || '';
      } else if (type === 'Product') {
        return _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name') || '';
      } else if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      } else if (type === 'Array') {
        return _.join(value, ', ');
      }
    },
    createFormsTable: function () {
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        }
      ];
      if (this.jobType !== 'User') {
        buttons.push({
          extend: 'excelHtml5',
          autoFilter: true,
          text: '<i class="far fa-file-excel"></i>',
          titleAttr: 'Excel',
          exportOptions: {
            columns: '.includeInExport'
          }
        });
      }
      this.formsTable = $('#submittedFormsList').DataTable({
        processing: true,
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.forms,
        rowId: 'id',
        columns: [
          {
            data: 'createdAt'
          },
          {
            data: 'createdAt'
          },
          {
            data: 'bankVerificationDate',
            defaultContent: ""
          },
          {
            data: 'previouslyAppliedDate',
            defaultContent: ""
          },
          {
            data: 'bankReceivedDate',
            defaultContent: ""
          },
          {
            data: 'bankResultDate',
            defaultContent: ""
          },
          {
            data: 'city',
            defaultContent: ""
          },
          {
            data: 'dateNinetyDays',
            defaultContent: ""
          },
          {
            data: 'owner',
            defaultContent: ""
          },
          {
            data: 'supervisor',
            defaultContent: ""
          },
          {
            data: 'branchManager',
            defaultContent: ""
          },
          {
            data: 'status'
          },
          {
            data: 'statusJustifications',
            defaultContent: ""
          },
          {
            data: 'appId',
            defaultContent: ""
          },
          {
            data: 'referenceNumber',
            defaultContent: ""
          },
          {
            data: 'remarks',
            defaultContent: ""
          },
          {
            data: 'offerType',
            defaultContent: ""
          },
          {
            data: 'product',
            defaultContent: ""
          },
          {
            data: 'nameDesired',
            defaultContent: ""
          },
          {
            data: 'title',
            defaultContent: ""
          },
          {
            data: 'firstName'
          },
          {
            data: 'lastName'
          },
          {
            data: 'fatherName',
            defaultContent: ""
          },
          {
            data: 'motherName',
            defaultContent: ""
          },
          {
            data: 'dateOfBirth',
            defaultContent: ""
          },
          {
            data: 'age',
            defaultContent: ""
          },
          {
            data: 'gender',
            defaultContent: ""
          },
          {
            data: 'maritalStatus',
            defaultContent: ""
          },
          {
            data: 'education',
            defaultContent: ""
          },
          {
            data: 'pancard'
          },
          {
            data: 'cibilScore',
            defaultContent: ""
          },
          {
            data: 'email',
            defaultContent: ""
          },
          {
            data: 'emailOfficial',
            defaultContent: ""
          },
          {
            data: 'mobileNumber',
            defaultContent: ""
          },
          {
            data: 'mobileNumberAlternate',
            defaultContent: ""
          },
          {
            data: 'residenceType',
            defaultContent: ""
          },
          {
            data: 'stayingSince',
            defaultContent: ""
          },
          {
            data: 'addressLine1',
            defaultContent: ""
          },
          {
            data: 'addressLine2',
            defaultContent: ""
          },
          {
            data: 'landmark',
            defaultContent: ""
          },
          {
            data: 'station',
            defaultContent: ""
          },
          {
            data: 'zipcode',
            defaultContent: ""
          },
          {
            data: 'employmentStatus',
            defaultContent: ""
          },
          {
            data: 'netMonthlyIncome',
            defaultContent: ""
          },
          {
            data: 'yearsEmployed',
            defaultContent: ""
          },
          {
            data: 'designation',
            defaultContent: ""
          },
          {
            data: 'company',
            defaultContent: ""
          },
          {
            data: 'companyListed',
            defaultContent: ""
          },
          {
            data: 'companyAddressLine1',
            defaultContent: ""
          },
          {
            data: 'companyAddressLine2',
            defaultContent: ""
          },
          {
            data: 'companyLandmark',
            defaultContent: ""
          },
          {
            data: 'companyStation',
            defaultContent: ""
          },
          {
            data: 'companyCity',
            defaultContent: ""
          },
          {
            data: 'companyZipcode',
            defaultContent: ""
          },
          {
            data: 'extCCBank1',
            defaultContent: ""
          },
          {
            data: 'extCCUsingFrom1',
            defaultContent: ""
          },
          {
            data: 'extCCTotalLimit1',
            defaultContent: ""
          },
          {
            data: 'extCCAvailableLimit1',
            defaultContent: ""
          },
          {
            data: 'extCCBank2',
            defaultContent: ""
          },
          {
            data: 'extCCUsingFrom2',
            defaultContent: ""
          },
          {
            data: 'extCCTotalLimit2',
            defaultContent: ""
          },
          {
            data: 'extCCAvailableLimit2',
            defaultContent: ""
          },
          {
            data: 'extCCBank3',
            defaultContent: ""
          },
          {
            data: 'extCCUsingFrom3',
            defaultContent: ""
          },
          {
            data: 'extCCTotalLimit3',
            defaultContent: ""
          },
          {
            data: 'extCCAvailableLimit3',
            defaultContent: ""
          },
          {
            data: 'extCCBank4',
            defaultContent: ""
          },
          {
            data: 'extCCUsingFrom4',
            defaultContent: ""
          },
          {
            data: 'extCCTotalLimit4',
            defaultContent: ""
          },
          {
            data: 'extCCAvailableLimit4',
            defaultContent: ""
          },
          {
            data: 'cardMailingAddress',
            defaultContent: ""
          },
          {
            data: 'extCCNumber',
            defaultContent: ""
          },
          {
            data: 'updatedBy',
            defaultContent: ""
          },
          {
            data: 'updatedAt',
            defaultContent: ""
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('DD-MM-YYYY');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: ["createdAt", "bankVerificationDate", "previouslyAppliedDate", "bankReceivedDate", "bankResultDate", "dateNinetyDays", "dateOfBirth"]
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('h:mm A');
              } else {
                return '';
              }
            },
            targets: "createdAtTime"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: ["owner", "supervisor", "branchManager", "updatedBy"]
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Status');
            },
            targets: "status"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Array');
            },
            targets: "statusJustifications"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Product');
            },
            targets: "product"
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Date');
            },
            targets: "updatedAt"
          },
          {
            visible: false,
            targets: [
              "previouslyAppliedDate",
              "bankReceivedDate",
              "bankResultDate",
              "dateNinetyDays",
              "statusJustifications",
              "appId",
              "referenceNumber",
              "remarks",
              "offerType",
              "product",
              "nameDesired",
              "title",
              "fatherName",
              "motherName",
              "dateOfBirth",
              "age",
              "gender",
              "maritalStatus",
              "education",
              "mobileNumberAlternate",
              "emailOfficial",
              "residenceType",
              "stayingSince",
              "addressLine1",
              "addressLine2",
              "landmark",
              "station",
              "zipcode",
              "employmentStatus",
              "netMonthlyIncome",
              "yearsEmployed",
              "designation",
              "company",
              "companyListed",
              "companyAddressLine1",
              "companyAddressLine2",
              "companyLandmark",
              "companyStation",
              "companyCity",
              "companyZipcode",
              "extCCBank1",
              "extCCUsingFrom1",
              "extCCTotalLimit1",
              "extCCAvailableLimit1",
              "extCCBank2",
              "extCCUsingFrom2",
              "extCCTotalLimit2",
              "extCCAvailableLimit2",
              "extCCBank3",
              "extCCUsingFrom3",
              "extCCTotalLimit3",
              "extCCAvailableLimit3",
              "extCCBank4",
              "extCCUsingFrom4",
              "extCCTotalLimit4",
              "extCCAvailableLimit4",
              "cardMailingAddress",
              "extCCNumber",
              "cibilScore"
            ]
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[0, "desc"], [1, "desc"]],
        pageLength: -1
      });

      this.formsTable.buttons()
          .container()
          .appendTo('#submittedFormsList_wrapper .col-sm-6:eq(0)');

      this.setupFormModal();
    },
    async updateTeamChartData() {
      if (!this.chartEnabled || !this.teamEnabled) {
        return;
      }
      let teamGroups = _.groupBy(this.forms, 'owner');
      let labels = [];
      let data = [];
      let backgroundColours = [];
      let borderColours = [];
      let colourIndex = 0;
      let colourIndexStep = Math.floor(chartColours.length / _.size(teamGroups));
      colourIndexStep = colourIndexStep < 1 ? 1 : colourIndexStep;
      _.forEach(teamGroups, (forms, member) => {
        labels.push(this.prettyPrint(member, 'User'));
        data.push(forms.length);
        backgroundColours.push(chartColours[colourIndex % chartColours.length].color);
        borderColours.push(chartColours[colourIndex % chartColours.length].border);
        colourIndex = colourIndex + colourIndexStep;
      });
      this.teamChartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundColours,
            borderColor: borderColours,
            data: data
          }
        ]
      };
      this.teamChartOptions = {
        responsive: true,
        tooltips: {
          callbacks: {
            title: (t, d) => {
              let toolTip = '';
              let groups = _.groupBy(this.forms, 'owner');
              _.forEach(groups, (forms, group) => {
                if (this.prettyPrint(group, 'User') === d.labels[t[0].index]) {
                  let statusGroups = _.groupBy(forms, 'status');
                  _.forEach(statusGroups, (forms, status) => {
                    let statusName = this.prettyPrint(status, 'Status');
                    if (toolTip === '') {
                      toolTip = statusName + ': ' + forms.length;
                    } else {
                      toolTip += '\n' + statusName + ': ' + forms.length;
                    }
                  })
                }
              });
              return toolTip;
            }
          }
        },
        plugins: {
          datalabels: {
            color: '#fff',
          }
        }
      }
    },
    async updateStatusChartData() {
      if (!this.chartEnabled) {
        return;
      }
      if (this.selectedStatuses.length > 1) {
        this.enableStatusChart = true;
        let statusGroups = _.groupBy(this.forms, 'status');
        let labels = [];
        let data = [];
        let backgroundColours = [];
        let borderColours = [];
        let colourIndex = 0;
        let colourIndexStep = Math.floor(chartColours.length / _.size(statusGroups));
        colourIndexStep = colourIndexStep < 1 ? 1 : colourIndexStep;
        _.forEach(statusGroups, (forms, statusId) => {
          labels.push(this.prettyPrint(statusId, 'Status'));
          data.push(forms.length);
          backgroundColours.push(chartColours[colourIndex % chartColours.length].color);
          borderColours.push(chartColours[colourIndex % chartColours.length].border);
          colourIndex = colourIndex + colourIndexStep;
        });
        this.statusChartData = {
          labels: labels,
          datasets: [
            {
              backgroundColor: backgroundColours,
              borderColor: borderColours,
              data: data
            }
          ]
        };
        this.statusChartOptions = {
          responsive: true,
          tooltips: {
            callbacks: {
              title: (t, d) => {
                let toolTip = '';
                let groups = _.groupBy(this.forms, 'status');
                _.forEach(groups, (forms, group) => {
                  if (this.prettyPrint(group, 'Status') === d.labels[t[0].index]) {
                    let teamGroups = _.groupBy(forms, 'owner');
                    _.forEach(teamGroups, (forms, member) => {
                      let userName = this.prettyPrint(member, 'User');
                      if (toolTip === '') {
                        toolTip = userName + ': ' + forms.length;
                      } else {
                        toolTip += '\n' + userName + ': ' + forms.length;
                      }
                    })
                  }
                });
                return toolTip;
              }
            }
          },
          plugins: {
            datalabels: {
              color: '#fff',
            }
          }
        }
      } else {
        this.enableStatusChart = false;
      }
    },
    async updateDateChartData() {
      if (!this.chartEnabled) {
        return;
      }
      let rangeSize = moment(this.dataDateRange.endDate).diff(this.dataDateRange.startDate, 'days');
      if (rangeSize > 0) {
        this.enableDateChart = true;
        let unit = null;
        if (rangeSize > 365) {
          unit = 'quarter';
          rangeSize = moment(this.dataDateRange.endDate).diff(this.dataDateRange.startDate, 'months');
        } else if (rangeSize > 180) {
          unit = 'month';
          rangeSize = moment(this.dataDateRange.endDate).diff(this.dataDateRange.startDate, 'months');
        } else if (rangeSize > 31) {
          unit = 'week';
          rangeSize = moment(this.dataDateRange.endDate).diff(this.dataDateRange.startDate, 'weeks');
        } else {
          unit = 'day';
        }
        let statusGroups = _.groupBy(this.forms, 'status');
        let colourIndex = Math.floor(Math.random() * (chartColours.length + 1));
        let colourIndexStep = Math.floor(chartColours.length / _.size(statusGroups));
        colourIndexStep = colourIndexStep < 1 ? 1 : colourIndexStep;
        let datasets = [];

        _.forEach(statusGroups, (groupForms, statusId) => {
          let dataset = {
            label: this.prettyPrint(statusId, 'Status'),
            data: [],
            borderColor: chartColours[colourIndex % chartColours.length].border,
            borderWidth: 2,
            backgroundColor: chartColours[colourIndex % chartColours.length].color
          };
          colourIndex = colourIndex + colourIndexStep;
          for (let index = 0; index < rangeSize + 1; index++) {
            let startEpoch = moment(this.dataDateRange.startDate).add(index, unit + 's').startOf(unit).format('X');
            let endEpoch = moment(this.dataDateRange.startDate).add(index, unit + 's').endOf(unit).format('X');
            let forms = _.filter(groupForms, (form) => {
              return form.createdAtEpoch >= startEpoch && form.createdAtEpoch <= endEpoch;
            });
            let xValue = moment(this.dataDateRange.startDate).add(index, unit + 's').startOf(unit).format('YYYY-MM-DD');
            dataset.data.push({x: xValue, y: forms.length, stack: this.prettyPrint(statusId, 'Status')});
          }
          datasets.push(dataset);
        });
        this.dateChartData = {
          datasets: datasets
        };
        this.dateChartOptions = {
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true,
              title: 'time',
              type: 'time',
              distribution: 'series',
              time: {
                unit: unit
              },
              offset: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }]
          },
          plugins: {
            datalabels: {
              color: '#fff',
              display: false
            }
          }
        }
      } else {
        this.enableDateChart = false;
      }
    },
    updateFormsTableData: function () {
      this.formsTable.clear().draw();
      this.formsTable.rows.add(this.forms);
      this.formsTable.columns.adjust().draw();
    },
    setupFormModal: function () {
      $('#submittedFormsList tbody').on('click', 'tr', (e) => {
        this.formDetails = this.formsTable.row(e.currentTarget).data();
        if (this.formDetails) {
          $("#formDetailsModal").modal('show');
        } else {
          this.formDetails = {};
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
